html, body {
  max-width: 100%;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Site-content {
  flex: 1;
}

.App-header {
  background-color: #282c34;
  min-height: 3vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 1.5vmin);
  color: white;
}

.app-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: .5em;
}


.app-header-right {
  display: flex;
  justify-content: flex-end;
}


.App-link {
  color: #61dafb;
}

.app-header-item {
  display: flex;
  align-items: center;
}

footer {
  margin-top: auto;
  text-align: center;
  font-size: calc(8px + 1vmin);
}


.stat-container {
  display: flex;
  justify-content: space-between;
}

.flex-container {
  display: flex;
}

.choose-circle-container {
  display: flex;
  justify-content: flex-start;
  margin: .5em;
}


.shot-row-container  {
  display: flex;
  justify-content: center;
}

.shot {
  display: flex;
}

.shot-item {
  flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
}

table{
  width: 100%;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.c-button-checked {
  background-color: #000000;
}

.circle-button {
  box-shadow: none !important;
  background-color: #DC7726;
  outline: none !important;
  outline-offset: none !important;
}

.btn-icon-add {
  color: green;
  /* font size ??? */
}

.btn-icon-minus {
  color: red;
}


.distance-circle {
  display: inline-block;
  line-height: 0px;
  border-radius: 50%;
  border: 2px solid;
  color: #0275d8;
}

.distance-circle span {
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 8px;
  margin-right: 8px;
}